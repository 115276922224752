import React from "react";
import './404.scss';
import logo404 from "../assets/images/404/404_logo.svg";
import { Helmet } from "react-helmet";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

class NotFoundPage extends React.Component {
  render() {
    return (
      <div className="Main">
      <Helmet
        defer={false}
        htmlAttributes={{
          lang: "hu",
        }}
        title="Oops... Az oldal nem található!"
      >
       <link href="https://fonts.cdnfonts.com/css/oswald-4" rel="stylesheet"></link>
      </Helmet>
        <div className="error-page-wrapper">
          <div className="content">
            <img className="logo" src={logo404} alt="logo" />
            <p className="title">OOPS!</p>
            <p className="error-code">404</p>
            <p className="description">Sajnos ez az oldal nem található!</p>
            <a href="/">
              <div className="button-box">
                  <p>Vissza a CMO főoldalára</p> <ArrowRightAltIcon className="arrow" />
              </div>
            </a>
          </div>
        </div>
      </div>

    )
  }
}

export default NotFoundPage;
